import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import { useTranslation } from 'gatsby-plugin-react-i18next';

const QuoteBox = () => {

  const { t } = useTranslation()
  const QuoteUrl = process.env.GATSBY_APP_QUOTE_URL
        ? process.env.GATSBY_APP_QUOTE_URL
        : "https://auto-quote.insureonline.com/quotes/new";

  return (
    <Container>
      <Row className="quote-box justify-content-center align-items-center p-3">
        <Col sm={6} lg={8} className="d-flex justify-content-center align-items-center mb-1">
          <span>{t("quotebox.copy")}</span>
        </Col>
        <Col sm={6} md={4} className="d-flex justify-content-center align-items-center mt-1">
          <Button href={QuoteUrl} className="quote-btn" variant="primary-1">{t("quotebox.buttonText")}</Button>
        </Col>
      </Row>
    </Container>
  )
}
export default QuoteBox
