import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import progressiveLogo from "../images/Progressive.jpg"
import travelersLogo from "../images/Travelers.jpg"
import stateAutoLogo from "../images/State-Auto.jpg"
import fcicLogo from "../images/FCIC.jpg"
import usicLogo from "../images/USIC.svg"
import aaaLogo from "../images/AAA-logo.jpg"

const Partners = () => {
  return (
    <Container fluid>
      <Row className="partners align-items-center justify-content-center mb-5">
        <Col sm={6} md={4} lg={3} xl={2} className="partner d-flex align-items-center justify-content-center">
          <img
          
            className="partner-1"
            src={progressiveLogo}
            alt="Progressive Insurance"
          />
        </Col>
        <Col sm={6} md={4} lg={3} xl={2} className="partner d-flex align-items-center justify-content-center">
          <img
           
            className="partner-2"
            src={travelersLogo}
            alt="Travelers Insurance"
          />
        </Col>
        <Col sm={6} md={4} lg={3} xl={2} className="partner d-flex align-items-center justify-content-center">
          <img
            alt="State Auto Insurance Companies"
            className="partner-3"
            src={stateAutoLogo}
          />
        </Col>
        <Col sm={6} md={4} lg={3} xl={2} className="partner d-flex align-items-center justify-content-center">
          <img
            alt="First Chicago Insurance Company(FCIC)"
            className="partner-4"
            src={fcicLogo}
         
          />
        </Col>
        <Col sm={6} md={4} lg={3} xl={2} className="partner d-flex align-items-center justify-content-center">
          <img
            alt="United Health Security Insurance Company(USIC)"
            className="partner-5"
            src={usicLogo}
          
          />
        </Col>
        <Col sm={6} md={4} lg={3} xl={2} className="partner d-flex align-items-center justify-content-center">
          <img
            alt="American Automobile Association(AAA)"
            className="partner-6"
            src={aaaLogo}
          />
        </Col>
      </Row>
    </Container>
  )
}

export default Partners
