import React from "react"
import { Card, Col, Container, Row } from "react-bootstrap"
import share from "../images/share.svg"
import compare from "../images/compare.svg"
import save from "../images/save.svg"
import QuoteBox from "./quote_box"
import {useTranslation} from 'gatsby-plugin-react-i18next';

const Cards = () => {

  const { t } = useTranslation();
  const renderMarkup = (markUp) => { 
    return {__html: markUp};
  }

  const setRenderedMarkup = (copyMarkup) => {
    return <div dangerouslySetInnerHTML={renderMarkup(copyMarkup)} />;
  }


  const cardsContent = [
    {
      img: share,
      title:t("cardscopy.title.share"),
      copy: t("cardscopy.share"),
    },
    {
      img: compare,
      title: t("cardscopy.title.compare"),
      copy: t("cardscopy.compare"),
    },
    {
      img: save,
      title: t("cardscopy.title.save"),
      copy: t("cardscopy.save"),
    },
  ]



  return (
    <Container fluid className="cards-container">
      <h2>{t("cardscopy.header") }</h2>
      <Row>
        {cardsContent.map((card, index) => {
          return (
          
              <Col lg={4} key={index+1}>
                <Card>
                  <Card.Body>
                    <img src={card.img} alt=""/>
                    <h3>{card.title}</h3>
                    {setRenderedMarkup(card.copy)}
                  </Card.Body>
                </Card>
              </Col>
          )
        })}
      </Row>
      <QuoteBox />
    </Container>
  )
}

export default Cards
