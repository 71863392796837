import PropTypes                      from "prop-types"
import React, { useState }            from "react"
import { Button }                     from "react-bootstrap"
import { useTranslation }             from 'gatsby-plugin-react-i18next';
//import addLangParam                   from '../helpers/addLanguageParamToLink'


const ZipCodeForm = ({ position }) => {
  const { t } = useTranslation();
  const [zipEntry, setEntry] = useState("");
  const updateEntry = e => {
    e.preventDefault()
    setEntry(e.target.value)
  };

  const HARD_CODED_AUTO_QUOTE_URL = "https://auto-quote.insureonline.com/quotes/new";
  const url = process.env.GATSBY_APP_QUOTE_URL ? `${process.env.GATSBY_APP_QUOTE_URL}` : HARD_CODED_AUTO_QUOTE_URL;

  // const getSearch = e => {
  //   e.preventDefault()
  //   if (zipEntry.match(/(\d{5})/)) {
  //     IsHomePage(window.location.pathname) ?
  //       mixpanel.track("Landing CTA Clicked", { "position": position }) :
  //       mixpanel.track(`Landing CTA Clicked - ${window.location.pathname}`, { "position": position });
  //   }
  // }

  // const mixpanelZipcodeInput = () => {
  //   mixpanel.track("Landing Page Zip", {
  //     "position": position,
  //     "zipcode": zipEntry
  //   })
  // }

 
  return (
    <form className="search-form form-inline">
      <div className="form-group">
        <input
          className="form-group search-bar"
          type="number"
          value={zipEntry}
          onChange={updateEntry}
          placeholder={t("zipCodeInput.inputLabel")}
          onInput={e => (e.target.value = e.target.value.slice(0, 5))}
        />
        <Button
          className="search-button"
          type="submit"
          variant="primary-1"
          href={`${url}?zip_code=${zipEntry}`}
        >
          {t("zipCodeInput.buttonText")}
        </Button>
      </div>
    </form>
  )
}


ZipCodeForm.propTypes = {
  formPosition: PropTypes.string
}
export default ZipCodeForm

