import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import ZipCodeForm from "./zipcode_form"
// import shield from "../images/shield.svg"
import { useTranslation } from 'gatsby-plugin-react-i18next';
import heroImage from "../images/Auto-Photo-Main.jpg"
import HappyFoxChatService from "../services/HappyFoxChatService";

const Hero = ({isILVS}) => {
  const { t } = useTranslation();
  const header = () => {
    return isILVS ? <h2>Illinois Drivers, Don't Get Caught Driving Without Auto Insurance. Get a Hassle-Free Quote in Minutes!</h2> : <h1>{t("hero.header")}</h1>
  }

  const subHeader = () => {
    const firstSentence = t("hero.subHeaders.09172021.s1")
    const secondSentenceP1 = t("hero.subHeaders.09172021.s2-1")
    const secondSentenceP2 = t("hero.subHeaders.09172021.s2-2")
    const secondSentenceP3 = t("hero.subHeaders.09172021.s2-3")
    const fullCopy = <p>
      {firstSentence} {secondSentenceP1} <span className="italic-orange">{secondSentenceP2}</span> {secondSentenceP3}
    </p>
    return fullCopy
  }

  const sr22Copy = () => {
    const text = t("hero.sr22.text")
    const linkText = t("hero.sr22.link")
    const fullCopy = <p className="strip-margin pt-2 pb-2"><span className="text-orange">{text}</span> <a className="text-reset font-italic underline" href="https://insureonline.com/Product/SR22" target="_blank" rel="noreferrer">{linkText}</a></p>
    return fullCopy
  }

  const customerCare = () => {
    return (
      <p className="text-orange">
            {/* Call us at: 844-358-5605 */}
            <span className="orange-link">
              {t("hero.contact.call_us") + " "}
              <u>
                <a href={'tel:' + t("hero.contact.number")}>{t("hero.contact.number")}</a>
              </u>
            </span>
          </p>
    )
  }

  const coverageCopy = () => {
    const happyFoxChatService = new HappyFoxChatService()
    const question = t("hero.coverage.question")
    const text_1 = t("hero.coverage.text.t1")
    const text_2 = t("hero.coverage.text.t2")
    const text_3 = t("hero.coverage.text.t3")
    const phone_text = t("hero.coverage.text.phone")

    const chatWithAgentText = () => {
      return (
        <span role="button" tabIndex={0} onClick={() => happyFoxChatService.openChatBox()} onKeyDown={() => happyFoxChatService.openChatBox()} className="font-italic text-reset underline">{text_1}</span>
      )
    }

    const fullCopy = <p className="strip-margin">
      <span className="text-orange">{question}</span> <br/>
      <span>{chatWithAgentText()} {text_2} <a href="tel:+18443585605" className="font-italic text-reset underline">{phone_text}</a> {text_3}</span>
    </p>
    return fullCopy
  }

  return (
    <Container fluid className="hero">
      <Row>
        <Col xl={8} md={12} className="left-container">
          {header()}
          {!isILVS ? subHeader() : <p>Uninsured motorists, get covered now. It’s the law! Compare rates and save hundreds on your <a style={{color:"#f16322"}} href="https://insureonline.com/Product/AutoInsurance">auto insurance</a>. No email or phone required - just free quotes. <a style={{color:"#f16322"}}href="https://insureonline.com/Product/IL-Verification-Program">Learn more about the Illinois Verification System.</a></p>}
          <ZipCodeForm position="hero" />
          {customerCare()}
          {sr22Copy()}
          {coverageCopy()}
        </Col>
        <Col xl={4} md={12} className="img-container">
          <img
            className="hero-img"
            src={heroImage}
            alt="Cheap Car Insurance Quotes Online"
          />
        </Col>
      </Row>
    </Container>
  )
}

export default Hero 